.listItem {
  flex-direction: column;
  align-items: flex-start;
}

.listHeader {
  display: flex;
  justify-content: space-between;
}

.listFooter {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
