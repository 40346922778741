@import '../../style/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 57px;
}

.containerColumn {
  flex-direction: column;
}

.logo {
  width: 16px;
}

.brand {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.title {
  color: #ffffff;
}

.sider {
  background-color: $primary-color;
  padding-top: 20px;
}
