@import '../../style/variables';

.outerLayout {
  min-height: 100vh !important;
  background: $primary-color;
}

.innerLayout {
  padding: 0 50px;
  border-top-left-radius: $border-radius-large;
}

.header {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  text-align: center;
}

.menuIcon {
  cursor: pointer;
}
