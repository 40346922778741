@import '../../style/variables';

.container {
  display: grid;
  width: 100%;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: 1fr;
}

.icon {
  color: $primary-color;
  font-size: 36px;
}

.card {
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: $title-margin-bottom !important;
}
