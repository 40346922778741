$font-path: './' !default;

@font-face {
  font-family: 'Radikal Black Italic';
  src: url('#{$font-path}/Radikal Black Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Black';
  src: url('#{$font-path}/Radikal Black.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Bold Italic';
  src: url('#{$font-path}/Radikal Bold Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Bold';
  src: url('#{$font-path}/Radikal Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Italic';
  src: url('#{$font-path}/Radikal Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Light Italic';
  src: url('#{$font-path}/Radikal Light Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Light';
  src: url('#{$font-path}/Radikal Light.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Medium Italic';
  src: url('#{$font-path}/Radikal Medium Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Medium';
  src: url('#{$font-path}/Radikal Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Thin Italic';
  src: url('#{$font-path}/Radikal Thin Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal Thin';
  src: url('#{$font-path}/Radikal Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal UltraThin Italic';
  src: url('#{$font-path}/Radikal UltraThin Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal UltraThin';
  src: url('#{$font-path}/Radikal UltraThin.otf') format('opentype');
}
@font-face {
  font-family: 'Radikal';
  src: url('#{$font-path}/Radikal.otf') format('opentype');
}
