.loginPage {
  background-color: #05002a;
  height: 100vh;
}

.heading {
  width: fit-content;
  margin: 10px auto 40px auto;
  font-weight: bold;
  letter-spacing: 1.7px;
  font-size: 55px;
  color: #ffffff;

  span {
    color: #95d6be;
  }
}

.widgetPadding {
  background-color: #05002a;
  padding-top: 100px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 21px;
  & button {
    &:last-child {
      margin-left: 10px;
    }
  }
}

.loginFormCont {
  max-width: 360px;
  padding: 0;
  margin: auto;
}

.createAccountButton {
  height: fit-content;
  background-color: white !important;
  color: black !important;
  display: flex;
}

.reportingDashboardText {
  color: white;
  font-size: large;
}

.accountTroubleLink {
  color: dodgerblue;
  display: flex;
  width: 50%;
  margin-left: auto;
  justify-content: flex-end;

  & button {
    &:last-child {
      padding-right: 0;
    }
  }
}

.loginButton {
  margin-left: 10px;
  margin-bottom: 1em;
}

.input {
  margin-bottom: 1em;
}
