.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.chartsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.chartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  margin: 20px !important;
}
.totalContainer {
  flex-basis: 300px;
}

.cover {
  height: 100px;
  display: flex;
  flex-basis: 100%;
  align-items: center;
}

.coverFetching {
  justify-content: center;
}
