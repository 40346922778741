.chartsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.chartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 20px !important;
}
