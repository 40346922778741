.ageSelectFirst {
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
}

.group {
  margin: auto 5px;
}

.label {
  margin-right: 10px;
}

.select {
  width: 100px;
}

.searchSelect {
  width: 250px;
}

.searchSelectSpinner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
