$primary-color: #2a2153;
$normal-color: #d9d9d9;
$link-color: #1890ff;
$info-color: #2a2153;
$success-color: #63a48c;
$warning-color: #faad14;
$error-color: #d06a6f;
$font-size-base: 14px;
$heading-color: rgba(0, 0, 0, 0.85);
$text-color: rgba(0, 0, 0, 0.65);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$disabled-color: rgba(0, 0, 0, 0.25);
$border-radius-base: 4px;
$border-color-base: #d9d9d9;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);

$border-radius-large: 20px;
$title-margin-bottom: 30px;
